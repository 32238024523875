<div class="container-fluid">
	<div class="row">
		<div class="col-12 noPaddings">
			<div class="headerClass">
				{{'dialogChangeStatusUser.headerTitle' | translate}}
			</div>
		</div>
		<div class="col-12 noPaddings">
			<div class="subHeaderClass">
				{{'dialogChangeStatusUser.subHeaderTitle' | translate}}
			</div>
		</div>
		<div class="col-12 noPaddings">
			<div class="row">
				<div class="col-4">
					<button class="cancelClass" (click)="closeModal()">
						{{'dialogChangeStatusUser.buttonCancel' | translate}}
					</button>
				</div>
				<div class="col-8 desactiveClass">
					<app-button
						(click)="toggleStatus()"
						[activo]="false"
						btnNombre="dialogChangeStatusUser.buttonDesactivate"
						btnType="primaryDangerMedium">
					</app-button>
				</div>
			</div>
		</div>
	</div>
</div>
