
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-toggle',
	templateUrl: './toggle.component.html',
	styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements ICellRendererAngularComp {
	status: boolean = false;
	statusCarrier: boolean = false;
	data: any;
	typeField: string = '';

	constructor() {}

	agInit(params: ICellRendererParams): void {
		this.typeField = params.colDef.field;
		this.data = params.data;
		if(this.typeField === "statusCarrier"){
			this.statusCarrier = this.data.statusCarrier;
		}
		if (this.typeField === 'status') {
			this.status =
				params.data.nombre_status_employee === 'ACTIVE' ? true : false;
		}
		if (this.typeField === 'accessqr') {
			this.status = params.data.slide_status;
		}
		if(this.typeField === 'enable_token'){
			this.status === params.data.enable_token;
		}
		if(this.typeField === 'id_status'){
			if(params.value ===1){
				this.status = true
			}
			else if(params.value === 2){
				this.status = false
			}

		}
		if(this.typeField === 'api_enabled'){
			if(params.data.api_enabled === 1){
				this.status = true;
			}
			else if(params.data.api_enabled === 0){
				this.status = false;
			}
		}
	}

	refresh(params: ICellRendererParams) {
		return false;
	}

	ngOnInit(): void {}
}
