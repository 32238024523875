<div *ngIf="typeField==='statusCarrier'">
	<mat-slide-toggle [(ngModel)]="statusCarrier"
	[disableRipple]="true">

	</mat-slide-toggle>
</div>


<div *ngIf="typeField==='id_status'">
	<mat-slide-toggle [(ngModel)]="status"
	[disableRipple]="true">

	</mat-slide-toggle>
</div>


<div *ngIf="typeField==='api_enabled'">
	<mat-slide-toggle [(ngModel)]="status"
	[disableRipple]="true">

	</mat-slide-toggle>
</div>

